<template>
  <div class="main">
    <div class="nav_bar">
      <div class="left">
        <el-button @click="toggleCollapse()">
          <i class="el-icon-s-fold"></i>
        </el-button>
        <!-- 面包屑导航区域 -->
        <el-breadcrumb v-if="currentRoute.path !== '/index'" l-breadcrumbseparator-class="el-icon-arrow-right">
          <template v-for="(item, index) in currentRoute.matched">
            <el-breadcrumb-item :key="item.path" v-if="index == 0" :to="{ path: '/' }" @click="activePathClick()">{{ item.meta.title }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="index !== 0" :key="item.path">{{
              item.meta.title
            }}</el-breadcrumb-item>
          </template>
        </el-breadcrumb>
        <el-breadcrumb v-if="currentRoute.path == '/index'" l-breadcrumbseparator-class="el-icon-arrow-right">
          <el-breadcrumb-item key="/index">首页</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="right">
        <el-button @click="screen()">
          <i class="el-icon-full-screen"></i>
        </el-button>
        <el-dropdown trigger="click">
          <el-button> 用户信息 </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>个人中心</el-dropdown-item>
              <el-dropdown-item @click.native="logoutFn">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="tags_view">
      <TagsView></TagsView>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import TagsView from "../TagsView/index.vue";
import { mapGetters } from "vuex";
import { FullScreen } from "@/utils/index.js";
import Cookies from "js-cookie";
export default {
  name: "AdminDemoIndex",
  components: {
    TagsView,
  },
  computed: {
    ...mapGetters(["isCollapse"]),
  },
  props: {
    currentRoute: Object,
  },
  data() {
    return {
      isFullscreen: false,
    };
  },
  mounted() {},
  methods: {
    screen() {
      this.isFullscreen = FullScreen(this.isFullscreen);
    },
    toggleCollapse() {
      this.$store.dispatch("setCollapse", !this.isCollapse);
    },
    logoutFn() {
      Cookies.set("isLogin", false);
      // this.$router.go(0);
      this.$router.push("/login"); // 返回登录页面
    },
  },
};
</script>

<style lang="scss" scoped>
.nav_bar {
  width: 100%;
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #f7f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 0 10px;
  .left {
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    div {
      margin: 0 8px;
    }
  }
  .el-button {
    height: 50px;
    border: none;
    background: #f7f5f5;
  }
}
.el-breadcrumb {
  width: 100%;
  margin: 0;
  padding: 0;
}
.tags_view {
  width: 100%;
  height: 50px;
  background: #fff;
  box-shadow: 0 0px 1px 0;
}
</style>
