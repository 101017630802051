// 全屏
export const FullScreen = (val) => {
  let element = document.documentElement;
  if (val) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  } else {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      // IE11
      element.msRequestFullscreen();
    }
  }
  return !val;
};

/**
 * 防抖处理 适用于点击事件
 * @param {function}  func        回调
 * @param {number}    wait      时间窗口的间隔
 * @param {boolean}    immediate     是否立即执行
 */

export function debounce(func, wait = 500, immediate = true) {
  let timer, ctx, args;
  const later = () =>
    setTimeout(() => {
      timer = null;
      if (!immediate) {
        func.call(ctx, ...args);
        ctx = args = null;
      }
    }, wait);

  return function () {
    if (!timer) {
      timer = later();
      if (immediate) {
        func.call(this, ...arguments);
      } else {
        ctx = this;
        args = arguments;
      }
    } else {
      clearTimeout(timer);
      timer = later();
    }
  };
}

/**
 * 节流处理  适用于懒加载等场景
 * @param {function}  func      回调
 * @param {number}    wait      时间窗口的间隔
 */

export function throttle(func, wait = 1000) {
  let timer,
    canRun = true;
  const later = () =>
    setTimeout(() => {
      func.call(this, ...arguments);
      canRun = true;
    }, wait);

  return function () {
    if (!canRun) return;
    canRun = false;
    clearTimeout(timer);
    timer = later();
  };
}
