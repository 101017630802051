<template>
  <el-menu
    background-color="#f7f5f5"
    text-color="rgb(87, 202, 235)"
    active-text-color="#1890ff"
    :unique-opened="uniqueOpened"
    :collapse="isCollapse"
    :collapse-transition="false"
    router
    :default-active="activePath"
  >
    <el-submenu
      :index="'/' + Items.path"
      v-for="Items in menulist"
      :key="Items.id"
    >
      <template slot="title">
        <i :class="Items.icon"></i>
        <span>{{ Items.name }}</span>
      </template>
      <template>
        <div v-for="Item in Items.children" :index="Item.id" :key="Item.id">
          <el-submenu
            v-if="Item.children && Item.children.length > 0"
            :index="'/' + Item.path"
          >
            <template slot="title">
              <i :class="Item.icon"></i>
              <span>{{ Item.name }}</span>
            </template>

            <el-menu-item
              :index="'/' + Items.path + '/' + Item.path + '/' + item.path"
              @click="saveNavState()"
              v-for="item in Item.children"
              :key="item.id"
            >
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.name }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-menu-item
            v-else
            :index="'/' + Items.path + '/' + Item.path"
            @click="saveNavState()"
          >
            <template slot="title">
              <i :class="Item.icon"></i>
              <span>{{ Item.name }}</span>
            </template>
          </el-menu-item>
        </div>
      </template>
    </el-submenu>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "asideBar",
  props: {
    menulist: Array,
  },

  data() {
    return {
      uniqueOpened: true,
    };
  },
  computed: {
    ...mapGetters(["isCollapse"]),
    activePath() {
      const activePath = this.$store.state.common.activePath;
      const router = activePath.split("/");
      if (router.length > 2) {
        return `/${router[1]}/${router[2]}/${router[3]}`;
      } else {
        return activePath;
      }
    },
  },
  mounted() {
    let path = window.sessionStorage.getItem("activePath") || "/system/home";
    this.$nextTick(() => {
      this.$store.dispatch("setActivePath", path);
    });
  },

  methods: {
    async saveNavState() {
      window.sessionStorage.setItem("activePath", this.$route.path);
      console.log(this.$route);
      this.$store.dispatch("setHomeTagsList", this.$route);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-menu {
  border: none;
  height: 100%;
  width: 100% !important;
}
::v-deep.el-submenu__title {
  border-radius: 5px;
  &:hover {
    border-radius: 5px;
  }
}
::v-deep.el-submenu {
  border-radius: 5px;
  .el-menu-item {
    &:hover {
      border-radius: 5px;
    }
  }
}
::v-deep.el-menu-item.is-active {
  border-radius: 5px;
  background-color: rgb(87, 202, 235) !important;
  color: #fff !important;
}
::v-deep.el-submenu__title,
.el-menu-item {
  i {
    margin-right: 20px;
    margin-left: 20px;
  }
}
</style>
