<template>
  <router-link class="sidebar-logo-link" to="/">
    <img class="sidebar-logo" src="@/assets/images/home/logo.png" />
    <h1 class="sidebar-title" v-if="!isCollapse">
      HealthIQ
      <span style="color: #57caeb"></span>管理后台
    </h1>
  </router-link>
</template>

<script>
export default {
  name: "SidebarLogo",
  props: {
    isCollapse: Boolean,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.sidebar-logo-link {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & .sidebar-logo {
    width: 49px;
    display: inline-block;
    height: 100%;
    margin-right: 12px;
  }

  & .sidebar-title {
    display: inline-block;
    margin: 0;
    color: #435ebe;
    font-weight: 600;
    line-height: 50px;
    font-size: 20px;
    font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    vertical-align: middle;
  }
}
</style>
