<template>
  <el-container>
    <el-aside :width="isCollapse ? '100px' : '300px'">
      <asideBar></asideBar>
    </el-aside>
    <el-scrollbar>
      <el-main>
        <mainBar :currentRoute="currentRoute"></mainBar>
      </el-main>
    </el-scrollbar>
  </el-container>
</template>
<script>
import asideBar from "./components/aside_bar/Index.vue";
import mainBar from "./components/main_bar/Index";

import API from "../../request/api.js";
import { mapGetters } from "vuex";
export default {
  name: "index",
  components: {
    asideBar,
    mainBar,
  },
  computed: {
    ...mapGetters(["isCollapse"]),
    currentRoute() {
      return this.$route;
    },
  },
  data() {
    return {};
  },

  mounted() {
    document.onselectstart = function () {
      return false;
    };
    this.menuList();
  },

  methods: {
    async menuList() {
      const res = await API.menuList();
      if (res.status !== 200) return "";
      this.$store.dispatch("menuList", res.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-container {
  width: 100%;
  height: 100vh;
  .el-scrollbar {
    width: 100%;
    height: 100vh;
    background: #fff;
  }
  .el-main {
    background: #fff;
  }
  .el-aside {
    background: #f7f5f5;
  }
}
</style>
