<template>
  <el-container>
    <el-header>
      <sidebarLogo :isCollapse="isCollapse"></sidebarLogo>
    </el-header>
    <el-main>
      <el-scrollbar wrap-class="scrollbar-wrapper">
        <Emun :menulist="menuList"></Emun>
      </el-scrollbar>
    </el-main>
  </el-container>
</template>
<script>
import sidebarLogo from "./SidebarLogo.vue";
import Emun from "./Emun.vue";
import { mapGetters } from "vuex";
export default {
  name: "asideBar",

  components: {
    sidebarLogo,
    Emun,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["menuList", "isCollapse"]),
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="scss">
.sidebar-container {
  // reset element-ui css
  .horizontal-collapse-transition {
    transition: 0s width ease-in-out, 0s padding-left ease-in-out,
      0s padding-right ease-in-out;
  }

  .scrollbar-wrapper {
    overflow-x: hidden !important;
  }

  .el-scrollbar__view {
    height: 100%;
  }

  .el-scrollbar__bar {
    &.is-vertical {
      right: 0px;
    }

    &.is-horizontal {
      display: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.el-container {
  width: 100%;
  height: 100%;
  .el-header {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;

    text-align: center;
    overflow: hidden;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .el-scrollbar {
    height: 100%;
  }

  .has-logo {
    .el-scrollbar {
      height: calc(100vh - 100px);
    }
  }
}
</style>
